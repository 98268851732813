import React from "react";
import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import Loader from "./components/Loader";
import "./styles/global.css";
import "./styles/admin.css";
import "./styles/login.css";
import "./styles/landing.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "./components/Snackbar.tsx";

const queryClient = new QueryClient();

const router = createBrowserRouter(routes);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <div className="app">
          <Suspense fallback={<Loader />}>
            <RouterProvider router={router} />
          </Suspense>
        </div>
      </SnackbarProvider>
    </QueryClientProvider>
  );
};

export default App;
